#projectOverview td, #projectOverview th {
  @apply border py-2;
}

#projectOverview th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#projectOverview td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#projectOverview table {
  @apply table-auto overflow-x-auto text-center;
}

#projectOverview thead {
  @apply text-[14px] border;
}

#projectOverview tbody {
  @apply text-[13px];
}