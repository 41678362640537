#vendorOverview td, #vendorOverview th {
  @apply border py-2;
}

#vendorOverview th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#vendorOverview td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#vendorOverview table {
  @apply table-auto overflow-x-auto text-center;
}

#vendorOverview thead {
  @apply text-[14px] border;
}

#vendorOverview tbody {
  @apply text-[13px];
}