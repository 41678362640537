#createQuotationComparisonForm h3 {
  @apply text-lg text-primary font-bold mb-3;
}

#createQuotationComparisonForm td, #createQuotationComparisonForm th {
  @apply border rounded-lg py-2;
}

#createQuotationComparisonForm th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#createQuotationComparisonForm td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#createQuotationComparisonForm table {
  @apply w-full table-auto text-center;
}

#createQuotationComparisonForm thead {
  @apply text-[14px] border;
}

#createQuotationComparisonForm tbody {
  @apply text-[13px];
}

#createQuotationComparisonForm input[type="number"] {
  @apply border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm;
}