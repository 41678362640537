#editRoleForm td, #editRoleForm th {
  @apply border py-2;
}

#editRoleForm th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#editRoleForm td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#editRoleForm table {
  @apply w-full table-auto overflow-x-auto text-center;
}

#editRoleForm thead {
  @apply text-[14px] border;
}

#editRoleForm tbody {
  @apply text-[13px];
}