#createRoleForm td, #createRoleForm th {
  @apply border py-2;
}

#createRoleForm th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#createRoleForm td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#createRoleForm table {
  @apply w-full table-auto overflow-x-auto text-center;
}

#createRoleForm thead {
  @apply text-[14px] border;
}

#createRoleForm tbody {
  @apply text-[13px];
}