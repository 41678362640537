#editPurchaseOrderForm h3 {
  @apply text-lg text-primary font-bold mb-3;
}

#editPurchaseOrderForm td, #editPurchaseOrderForm th {
  @apply border py-2;
}

#editPurchaseOrderForm th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#editPurchaseOrderForm td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#editPurchaseOrderForm table {
  @apply w-full table-auto overflow-x-auto text-center;
}

#editPurchaseOrderForm thead {
  @apply text-[14px] border;
}

#editPurchaseOrderForm tbody {
  @apply text-[13px];
}

#editPurchaseOrderForm input[type="number"] {
  @apply border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm;
}

#editPurchaseOrderForm input[type="submit"] {
  @apply text-white bg-primary rounded-[40px] w-[299px] h-9 cursor-pointer;
}