.space {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-left: 250px;
  padding-bottom: 100px;
  background-color: var(--lightBlue);
}

@media (max-width: 768px) {
  .space {
    padding-left: 0;
  }
}

.btn-primary {
  @apply bg-primary py-3 px-6 text-pure font-medium hover:bg-primaryHover rounded-md cursor-pointer flex items-center gap-1;
}

.btn {
  @apply bg-primary py-1 px-5 text-pure rounded-[40px]
}

.btn-primary.p-sm {
  @apply py-2;
}

@media (max-width: 700px) {
  .btn-primary {
    @apply px-4 py-2 text-sm;
  }
}

.total-count {
  @apply bg-gray-100 rounded-md px-2 text-xl font-semibold;
}

.textField,
.react-datepicker__input-container input {
  @apply border py-2 px-3 text-gray-900 rounded-md outline-none w-full md:w-11/12 border-[#8E8EA1];
}

.textField:focus {
  border-color: rgb(0, 153, 255)
}

@media (max-width : 650px) {
  /* .textField {
        @apply py-2 px-2 text-[15px]
    } */
}

.shadow-bg {
  @apply bg-pure rounded-lg shadow-md border;
}

.main-heading {
  @apply font-semibold text-2xl flex items-center gap-2;
}

table {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary);
}