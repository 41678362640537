#createQuotationForm h3 {
  @apply text-lg text-primary font-bold mb-3;
}

#createQuotationForm td, #createQuotationForm th {
  @apply border py-2;
}

#createQuotationForm th {
  @apply font-semibold text-primary px-6 py-4 text-center;
}

#createQuotationForm td {
  @apply text-gray-900 px-2 whitespace-nowrap;
}

#createQuotationForm table {
  @apply w-full table-auto overflow-x-auto text-center;
}

#createQuotationForm thead {
  @apply text-[14px] border;
}

#createQuotationForm tbody {
  @apply text-[13px];
}

#createQuotationForm input[type="number"] {
  @apply border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm;
}